export const environment = {
    type: 'staging',
    apiUrl: 'orchestrator-service.dev.exafysolutions.ae',
    orchestratorUrl: 'orchestrator-service.dev.exafysolutions.ae',
    n8nUrl: 'n8n.dev.exafysolutions.ae/webhook',
    analyticsUrl: 'https://metabase.dev.exafysolutions.ae/public/dashboard/1c3a1f14-7f5a-4c2c-a0d3-224d0f6c9ac8?tab=4-messages',
    wssScheme: 'wss',
    httpScheme: 'https',
    design1: 'https://design1.dev.exafysolutions.ae/',
    design2: 'https://design2.dev.exafysolutions.ae/',
    design3: 'https://design3.dev.exafysolutions.ae/',
    design4: 'https://design4.dev.exafysolutions.ae/',
    design5: 'https://design5.dev.exafysolutions.ae/'
 };
