// without information about actions, languages, shortcuts, knowledge, voices
export class AgentConfigurationModel {
    actionEnabled: boolean = false;
    age: number = 0;
    analyticEnabled: boolean = false;
    answerLength: number = 0;
    authenticationEnabled: boolean = false;
    avatarEnabled: boolean = false;
    //avatarTalk: string[] = [];
    behavior: string = '';
    cacheEnabled: boolean = false;
    communicationStyle: string = '';
    gender: string = '';
    id: string = '';
    interests: string[] = [];
    knowledgeDatabaseType: string = '';
    knowledgeScope: string = '';
    location: string = '';
    nResults: number = 0;
    nResultsAttachment: number = 0;
    nStructuredResults: number = 0;
    name: string = '';
    promptId: string = '';
    relatedQuestionsEnabled: string = '';
    responseFormat: string = '';
    retrievalType: string = '';
    similarityThreshold: number = 0;
    sqlEnabled: boolean = false;
    temperature: number = 0;
    textSplitterOverlap: number = 0;
    textSplitterTokenLength: number = 0;
    textSplitterType: string = '';
    timezone: string = '';
    textSplitterWindowSize: number = 0;
    textSplitterBufferSize: number = 0;
    textSplitterBreakpointPercentileThreshold: number = 0;
    customClassNamespace: string = '';
    additionalInstructions: string = '';
    specialistDescription: string = '';
    specialistWebhook: string = '';
    intermediateResponse: boolean = false;

    constructor() {}
}
