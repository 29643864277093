import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";
import { HttpHeaders } from "@angular/common/http";

import { ConfigService } from "./config.service";
import { RestResponseModel } from "../models/rest.model";
import { RestService } from "./rest.service";
import { ActionModel, ActionPaginationModel } from "../models/action/action.model";

@Injectable({
    providedIn: 'root'
})
export class ActionService {
    private actionPagination = new BehaviorSubject<ActionPaginationModel | null>(null);
    private assistantActions = new BehaviorSubject<ActionModel[] | null>(null);

    private selectedAction = new BehaviorSubject<any>({});

    // TODO need token
    headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    constructor(private config: ConfigService, private rest: RestService) { }

    getSelectedAction(): Observable<any> {
        return this.selectedAction;
    }

    getAssistantActionsResponse(): Observable<ActionModel[] | null> {
        return this.assistantActions;
    }

    getActionPaginationResponse(): Observable<ActionPaginationModel | null> {
        return this.actionPagination;
    }

    setSelectedAction(action: any) {
        this.selectedAction.next(action);
    }

    setActionPaginationResponse(actionPagination: ActionPaginationModel | null) {
        this.actionPagination.next(actionPagination);
    }

    setAssistantActionsResponse(assistantActions: ActionModel[] | any) {
        this.assistantActions.next(assistantActions);
    }

    async getActionList(search: string): Promise<RestResponseModel<ActionModel[]>> {
        const url = `${this.config.getUrl()}/action/all?search=${search}`;

        try {
            const response = await lastValueFrom(
                this.rest
                .setHost(url)
                .setMethod("GET")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .create<ActionModel[]>()
            );

            return {
                status: response.status,
                message: response.message,
                body: response.body
            }
        } catch (e: any) {
            return {
                status: e.status,
                message: e.message,
                body: []
            };
        }
    }

    async assignAssistantActions(assistantId: string, actionIds: any) {
        const url = `${this.config.getUrl()}/agent/${assistantId}/actions/assign`;

        try {
            const response = await lastValueFrom(
                this.rest
                .setHost(url)
                .setMethod("POST")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .setContent(actionIds)
                .create()
            );

            return {
                status: response.status,
                message: response.message,
                body: {} as ActionModel
            }
        } catch (e: any) {
            return {
                status: e.status,
                message: e.message,
                body: {} as ActionModel
            };
        }
    }

    async getActionPaginationList(page: number, size: number, search: string): Promise<RestResponseModel<ActionPaginationModel>> {
        const url = `${this.config.getUrl()}/action/all/pagination?page=${page}&size=${size}&search=${search}`;

        try {
            const response = await lastValueFrom(
                this.rest
                .setHost(url)
                .setMethod("GET")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .create<ActionPaginationModel>()
            );

            return {
                status: response.status,
                message: response.message,
                body: response.body
            }
        } catch (e: any) {
            return {
                status: e.status,
                message: e.message,
                body: {} as ActionPaginationModel
            };
        }
    }

    async getAction(actionId: string) {
        const url = `${this.config.getUrl()}/action/${actionId}`;
        try {
            const response = await lastValueFrom(
                this.rest
                .setHost(url)
                .setMethod("GET")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .create<ActionModel>()
            );

            return {
                status: response.status,
                message: response.message,
                body: response.body
            }
        } catch (e: any) {
            return {
                status: e.status,
                message: e.message,
                body: {} as ActionModel
            };
        }
    }

    async deleteAction(actionId: string) {
        const url = `${this.config.getUrl()}/action/${actionId}/delete`;
        try {
            const response = await lastValueFrom(
                this.rest
                .setHost(url)
                .setMethod("DELETE")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .create()
            );

            return {
                status: response.status,
                message: response.message
            }
        } catch (e: any) {
            return {
                status: e.status,
                message: e.message,
                body: null
            };
        }
    }

    async createAction(action: ActionModel) {
        const url = `${this.config.getUrl()}/action/create`;

        try {
            const response = await lastValueFrom(
                this.rest
                .setHost(url)
                .setMethod("POST")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .setContent(action)
                .create<ActionModel>()
            );

            return {
                status: response.status,
                message: response.message,
                body: response.body
            };
        } catch (e: any) {
            return {
                status: e.status,
                message: e.message,
                body: {} as ActionModel
            };
        }
    }

    async updateAction(actionId: string, action: ActionModel) {
        const url = `${this.config.getUrl()}/action/${actionId}/update`;

        try {
            const response = await lastValueFrom(
                this.rest
                .setHost(url)
                .setMethod("POST")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .setContent(action)
                .create<ActionModel>()
            );

            return {
                status: response.status,
                message: response.message,
                body: response.body
            };
        } catch (e: any) {
            return {
                status: e.status,
                message: e.message,
                body: {} as ActionModel
            };
        }
    }
}
