import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";

import { AlertService } from "../../../core/services/alert.service";
import { DialogService } from "../../../core/services/dialog.service";
import { KnowledgeService } from "../../../core/services/knowledge.service";
import {
    AssistantKnowledgeDisplayModel,
    KnowledgeId,
    KnowledgeModel
} from "../../../core/models/knowledge/knowledge.model";
import { AssistantService } from "../../../core/services/assistant.service";
import { EventService } from "../../../core/services/event.service";
import { NavBarOptionModel } from "../../../core/models/navBarOption.model";
import { ErrorService } from "../../../core/services/error.service";

@Component({
    selector: 'app-createNewAssistant-knowledge-to-assistant-modal',
    templateUrl: './add-knowledge-to-assistant-modal.component.html',
    styleUrl: './add-knowledge-to-assistant-modal.component.scss'
})
export class AddKnowledgeToAssistantModalComponent implements OnInit, OnDestroy {
    dialogTitle = 'Manage knowledge to ';
    saveButton = 'Save changes';
    noSearchResultSection: string[] = [
        `No search results found!`,
        `Try adjusting your search criteria or create new knowledge if it doesn't exist.`
    ]

    assistantData: any
    allKnowledges: KnowledgeModel[] = [ ];
    assistantKnowledgeDisplayList: AssistantKnowledgeDisplayModel[] = [ ];
    originalAssistantKnowledgeDisplay: AssistantKnowledgeDisplayModel[] = [ ];
    searchValue: string = '';
    filterValue: { type: string[] | null; selected: boolean } = { type: [], selected: false };
    checkedKnowledgeMap: { [key: string]: boolean } = {};

    assistantKnowledgeSubscription!: Subscription;
    searchValueSubscription!: Subscription;
    filterValueSubscription!: Subscription;

    @HostListener('document:keydown.escape', ['$event'])
    handleEscape(event: KeyboardEvent) {
        this.closeDialog();
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any | null,
        private alert: AlertService,
        private dialog: DialogService,
        private knowledge: KnowledgeService,
        private assistant: AssistantService,
        private event: EventService,
        private error: ErrorService,
        private dialogRef: MatDialogRef<AddKnowledgeToAssistantModalComponent>) {
    }

    async ngOnInit() {
        this.assistantData = this.data;
        this.dialogTitle = this.dialogTitle + this.assistantData.name;

        const option: NavBarOptionModel = { search: 'Type here to search knowledge...', create: 'Create new knowledge' };
        this.event.setContentFromNavbarOption(option);

        await this.loadKnowledge();

        this.assistantKnowledgeSubscription = this.knowledge.getAssistantKnowledgeResponse().subscribe(knowledges => {
            if (knowledges != null) {
                this.allKnowledges = knowledges;
                this.checkAssistantKnowledge();
            }
        })

        this.searchValueSubscription = this.event.getSearchValue().subscribe(search => {
            this.searchValue = search || '';
            this.applyFilters();
        });

        this.filterValueSubscription = this.event.getFilterValue().subscribe(filter => {
            if (filter) {
                this.filterValue.type = filter.type;
                this.filterValue.selected = filter.selected;
            } else {
                this.filterValue = { type: [], selected: false };
            }
            this.applyFilters();
        });
    }

    async loadKnowledge() {
        this.alert.showLoading();
        const response = await this.knowledge.getKnowledgeList('');
        this.allKnowledges = response.body;

        // Create a map of knowledge id and its assigned status
        this.allKnowledges.forEach(knowledge => {
            if (this.assistantData.knowledge) {
                this.checkedKnowledgeMap[knowledge.id] = this.assistantData.knowledge.some((k: KnowledgeModel) => k.id === knowledge.id);
            } else {
                this.checkedKnowledgeMap[knowledge.id] = false;
            }
        });

        this.checkAssistantKnowledge();
        this.alert.close();
    }

    checkAssistantKnowledge() {
        this.assistantKnowledgeDisplayList = this.allKnowledges.map(knowledge => ({
            knowledge: knowledge,
            assignedToAssistant: this.checkedKnowledgeMap[knowledge.id] || false
        }));

        this.originalAssistantKnowledgeDisplay = this.assistantKnowledgeDisplayList;
    }

    toggleCheckbox(assistantKnowledgeDisplay: AssistantKnowledgeDisplayModel): void {
        assistantKnowledgeDisplay.assignedToAssistant = !assistantKnowledgeDisplay.assignedToAssistant;
        this.checkedKnowledgeMap[assistantKnowledgeDisplay.knowledge.id] = assistantKnowledgeDisplay.assignedToAssistant;
    }

    addNewKnowledge() {
        this.dialog.openCreateKnowledgeDialog()
    }

    isAssignableKnowledgeListEmpty(): boolean {
        return !(this.assistantKnowledgeDisplayList && this.assistantKnowledgeDisplayList.length > 0);
    }

    applyFilters() {
        this.assistantKnowledgeDisplayList = this.allKnowledges
        .filter(knowledge =>
            (!this.searchValue || knowledge.name.toLowerCase().includes(this.searchValue.toLowerCase()))
        )
        .filter(knowledge =>
            (!this.filterValue.type || this.filterValue.type.length === 0 || this.filterValue.type.includes(knowledge.knowledgeType))
        )
        .map(knowledge => ({
            knowledge: knowledge,
            assignedToAssistant: this.checkedKnowledgeMap[knowledge.id] || false
        }));

        if (this.filterValue.selected) {
            this.assistantKnowledgeDisplayList = this.assistantKnowledgeDisplayList.filter(
                assistantKnowledgeDisplay => assistantKnowledgeDisplay.assignedToAssistant === this.filterValue.selected
            );
        }
    }

    async save() {
        this.alert.showLoading();

        try {
            const knowledgeIds: KnowledgeId[] = Object.keys(this.checkedKnowledgeMap).filter(key => this.checkedKnowledgeMap[key]).map(id => ({ id }));

            const response = await this.knowledge.assignAssistantKnowledge(this.assistantData.id, knowledgeIds);
            if (response.status !== 200) {
                if (response.status === 403) {
                    this.closeDialog();
                }
                this.error.setError(response);
                return;
            }

            const getAgent = await this.assistant.fetchAssistant(this.assistantData.id);
            if (getAgent.status !== 200) {
                if (getAgent.status === 403) {
                    this.closeDialog();
                }
                this.error.setError(getAgent);
                return;
            }

            this.assistant.setSelectedAssistantResponse(getAgent.body);

            this.closeDialog();
            this.alert.close();
            this.alert.showSucess('Knowledge assigned successfully.', 'Check your knowledge list in Assistant Knowledge tab.');
        } catch (error) {
            console.error('Error assigning knowledgeList:', error);
        }
    }


    closeDialog() {
        this.dialog.closeNestedDialog(this.dialogRef);
    }

    ngOnDestroy() {
        this.assistantKnowledgeSubscription.unsubscribe();
        this.searchValueSubscription.unsubscribe();
        this.filterValueSubscription.unsubscribe();
    }
}
