import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor() { }

    showLoading() {
         Swal.fire({
            allowOutsideClick: false,
            background: 'rgba(255, 255, 255, 0.0)',
            backdrop: 'rgba(255, 255, 255, 0.5)',
            heightAuto: false,
            html: `
                <div class="loader-container">
                    <div class="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <p class="loading-text">Loading</p>
                </div>`,
            didOpen: () => {
            Swal.showLoading();
            }
        });
    }

    showError(title: string, text: string) {
        Swal.fire({
            allowOutsideClick: false,
            imageUrl: '../../../assets/icons/error.svg',
            imageAlt: 'Custom error image',
            title: title,
            text: text,
            heightAuto: false
        });
    }

    showSucess(title: string, text: string) {
        Swal.fire({
            allowOutsideClick: false,
            imageUrl: '../../../assets/icons/sucess.svg',
            imageAlt: 'Custom sucess image',
            title: title,
            text: text,
            heightAuto: false
        });
    }

    showSaveChanges(title: string, text: string, confirmButton: string): Promise<any> {
        return Swal.fire({
            allowOutsideClick: false,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: confirmButton,
            imageUrl: '../../../assets/icons/info.svg',
            imageAlt: 'Custom save changes image',
            title: title,
            text: text,
            heightAuto: false,
            customClass: {
                popup: 'changes-popup',
                image: 'changes-image',
                actions: 'changes-actions',
                title: 'changes-title',
                htmlContainer: 'changes-html-container',
            }
        });
    }

    showDelete(title: string, text: string): Promise<any> {
        return Swal.fire({
            allowOutsideClick: false,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Delete',
            imageUrl: '../../../assets/icons/delete.svg',
            imageAlt: 'Custom delete image',
            title: title,
            text: text,
            heightAuto: false,
            customClass: {
                popup: 'delete-popup',
                image: 'delete-image',
                actions: 'delete-actions',
                title: 'delete-title',
                htmlContainer: 'delete-html-container',
            }
        });
    }

    close() {
        Swal.close();
    }
}
