<div class="box-sidenavbar">
    <div class="back-container">
        <button id="id-back" type="button" class="back-button" (click)="back()">
            <img [src]="backButtonIcon" alt="Back icon">{{ backButton }}
        </button>
        <button id="id-open-analytics" type="button" class="analytics-button" (click)="openAnalytics()">
            <img [src]="analyticsButtonIcon" alt="Analytics icon">{{ analyticsButton }}
        </button>
    </div>
    <div class="sidenavbar-list">
        <ul>
            <li id="id-nav-option" *ngFor="let link of links; let i = index" [class.active]="activeIndex === i"
                (click)="setActiveLink(i, link.name)" [routerLink]="link.href">
                <span class="active-rect"></span>
                <div class="sidenavbar-list-item">
                    <app-assistant-icon *ngIf="link.name === 'Assistants'" width="24" height="24"></app-assistant-icon>
                    <app-knowledge *ngIf="link.name === 'Learning Hub'"></app-knowledge>
                    <app-action *ngIf="link.name === 'Actions'"></app-action>
                    <a>{{ link.name }}</a>
                </div>
            </li>
        </ul>
    </div>
</div>

