import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { DialogService } from "../../../core/services/dialog.service";
import { AlertService } from "../../../core/services/alert.service";
import { ActionService } from "../../../core/services/action.service";
import { ActionModel } from "../../../core/models/action/action.model";
import { ActionHeaderModel } from "../../../core/models/action/header.model";
import { ActionParameterModel, ActionParameterValueModel } from "../../../core/models/action/parameter.model";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { ErrorService } from "../../../core/services/error.service";

@Component({
    selector: 'app-action-modal',
    templateUrl: './action-modal.component.html',
    styleUrl: './action-modal.component.scss'
})
export class ActionModalComponent implements OnInit, OnDestroy {
    dialogTitle = 'Add new Action';
    actionLabels: string[] = ['Action Name', 'Action Description', 'Enter action name', 'Enter action description']
    componentLabel: string = 'Select Component';
    componentSelection: string[] = ['Header', 'Parameter'];
    methodLabel: string = 'Action Method and Action URL';
    methodEnterLabel: string = 'Enter Action URL...';
    methods: string[] = ['GET', 'POST', 'PUT', 'DELETE'];
    headerLabels: string[] = ['Custom Header', 'Custom Header Value', 'Enter custom header name', 'Enter custom header value'];
    statusLabel: string[] = ['Select if Status Check is needed', 'Status Check'];
    parameterLabels: string[] = ['Parameter Name', 'Parameter Description', 'Parameter Type', 'Enter parameter name', 'Enter parameter description', 'Enter parameter type'];
    parameterValues: string[] = ['Available values', 'Enter value'];
    addValueButton:  string = 'Add value';
    addParameterButton: string = 'Add new parameter';
    addNewHeaderButton: string = 'Add new Header';
    buttonLabel: string = 'Create Action';

    selectedComponent: string = 'Header';

    actionName: string = '';
    actionDescription: string = '';
    isChecked: boolean = false;
    actionUrl: string = '';
    actionMethod: string = 'GET';

    // Header
    isDropdownOpen: boolean = false;
    canAddHeader: boolean = false;
    headers: ActionHeaderModel[] = [{ id: '', headerKey: '', headerValue: '' }];

    // Parameter
    canAddParameter: boolean = false;
    parameters: ActionParameterModel[] = [{ id: '', name: '', description: '', parameterType: '', parameterValues: [ { parameterValue: ''}] }];

    // Values
    canAddValue: boolean = false;
    values: ActionParameterValueModel[] = [ { parameterValue: '' } ];
    actionForm!: FormGroup; // Using reactive forms
    errorNameRequiredMessage = 'Action name is required.'
    errorDescriptionRequiredMessage = 'Action description is required.'
    errorMinLengthMessage: string = 'Action name must contain more than 3 characters.'
    errorUrlMessage: string = 'Action URL must start with http:// or https://.'

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ActionModel | null, // Take action datas from parent dialog
        private dialogRef: MatDialogRef<ActionModalComponent>,
        private dialog: DialogService,
        private alert: AlertService,
        private action: ActionService,
        private error: ErrorService,
        private formBuilder: FormBuilder) { }

    ngOnInit(): void {
        this.initializeForm();

        if (this.data) {
            this.dialogTitle = 'Edit Action';
            this.actionName = this.data.name;
            this.actionMethod = this.data.actionMethod;
            this.isChecked = this.data.statusCheck;
            this.actionDescription = this.data.description;
            this.headers = this.data.headers;

            this.parameters = this.data.parameters.map(parameter => ({
                ...parameter,
                parameterValues: parameter.parameterValues && parameter.parameterValues.length > 0
                    ? parameter.parameterValues
                    : [{ parameterValue: '' }]  // Add empty parameterValue if he doesn't exist
            }));

            this.buttonLabel = 'Save Action';

            if (this.data.actionUrl) {
                this.actionUrl = this.data.actionUrl;

                this.actionForm.patchValue({
                    actionUrl: this.data.actionUrl
                });
            }

            this.actionForm.patchValue({
                actionName: this.data.name,
                actionDescription: this.data.description
            });

            this.canAddHeader = this.headers.every(header => this.isHeaderValid(header));
            this.canAddParameter = this.parameters.every(parameter => this.isParameterValid(parameter));
            this.canAddValue = this.parameters.every(parameter => parameter.parameterValues.every(value => this.isValueValid(value.parameterValue)));
        }
    }

    private initializeForm() {
        this.actionForm = this.formBuilder.group({
            actionName: ['', Validators.required],
            actionDescription: ['', Validators.required],
            actionUrl: ['', this.optionalUrlValidator]
        });
    }

    private optionalUrlValidator(control: AbstractControl): ValidationErrors | null {
        const value = control.value;
        if (!value) {
            return null;
        }

        const isValid = value.startsWith('http://') || value.startsWith('https://');
        return isValid ? null : { invalidUrl: true };
    }

    getActionFormElement(control: string) {
        return this.actionForm.get(control);
    }

    isFieldInvalid(controlName: string): boolean {
        const control = this.actionForm.get(controlName);
        return !!(control?.invalid && (control.dirty || this.actionForm.get(controlName)?.touched)); // Parse into boolean type with !!
    }

    isUrlValid(): boolean {
        const pattern = /^(https?:\/\/)/;
        return pattern.test(this.actionUrl);
    }

    resetFields(){
        this.actionForm.reset();
    }

    selectComponent(component: string) {
        this.selectedComponent = component;
    }

    async submitAction() {
        this.alert.showLoading();

        const newAction = this.createActionObject();

        let actionResponse;

        if (this.data && this.data.id) {
            actionResponse = await this.action.updateAction(this.data.id, newAction);
        } else {
            actionResponse = await this.action.createAction(newAction);
        }

        //const actionResponse = await this.action.createAction(newAction);
        if (actionResponse.status !== 200) {
            if (actionResponse.status === 403) {
                this.dialog.closeNestedDialog(this.dialogRef);
            }
            this.error.setError(actionResponse);
            return;
        }

        const response = await this.action.getActionPaginationList(1, 10, '');
        this.alert.close();

        if (response.status !== 200) {
            if (response.status === 403) {
                this.dialog.closeNestedDialog(this.dialogRef);
            }
            this.error.setError(response);
        } else {
            this.action.setActionPaginationResponse(response.body);
        }

        this.dialog.closeNestedDialog(this.dialogRef);
        this.alert.close();

        this.alert.showSucess(
            this.data && this.data.id
                ? 'Successfully updated!' : 'Successfully added!', 'Check action availability in Actions.'
        );
    }

    createActionObject() {
        const action: ActionModel = {} as ActionModel;

        action.name = this.actionForm.get('actionName')?.value;
        action.actionMethod = this.actionMethod;
        action.statusCheck = this.isChecked;
        action.actionUrl = this.actionForm.get('actionUrl')?.value;
        action.description = this.actionForm.get('actionDescription')?.value;
        action.headers = this.headers;
        //action.parameters = this.parameters;

        action.parameters = this.parameters.map(parameter => ({
            ...parameter,
            parameterValues: parameter.parameterValues.filter(value => value.parameterValue.trim() !== '')
        })).filter(parameter => parameter.parameterValues.length > 0 || parameter.parameterValues.every(value => value.parameterValue.trim() !== ''));

        return action;
    }

    addHeader() {
        if (this.canAddHeader) {
            // Add new header row
            this.headers.push({ id: '', headerKey: '', headerValue: '' });
            this.canAddHeader = false;
        }
    }

    addValue(parameter: ActionParameterModel) {
        if (this.canAddValue) {
            // Add new value row
            parameter.parameterValues.push({ parameterValue: '' });
            this.canAddValue = false;
        }
    }

    addParameter() {
        if (this.canAddParameter) {
            // Add new parameter container
            this.parameters.push({ id: '', name: '', description: '', parameterType: '', parameterValues: [ { parameterValue: ''}] });
            this.canAddParameter = false;
        }
    }

    removeHeader(index: number): void {
        this.headers.splice(index, 1);
        this.canAddHeader = true;
    }

    removeParameter(index: number): void {
        this.parameters.splice(index, 1);
        this.canAddParameter = true;
    }

    removeParameterValue(parameter: ActionParameterModel, index: number): void {
        parameter.parameterValues.splice(index, 1);
        this.canAddValue = true;
    }

    get checkHeaderFilled(): boolean {
        this.canAddHeader = this.headers.every(header => this.isHeaderValid(header));
        return this.canAddHeader;
    }

    get checkParameterFilled(): boolean {
        this.canAddParameter = this.parameters.every(parameter => this.isParameterValid(parameter));
        return this.canAddParameter;
    }

    checkValueFilled(parameter: ActionParameterModel, parameter_i: number): boolean {
        //const currentValue = parameter.parameterValues[index];
        //this.canAddValue = this.isValueValid(currentValue.parameterValue);

        this.canAddValue = parameter.parameterValues.every(curr => this.isValueValid(curr.parameterValue));

        const currentParameter = this.parameters[parameter_i];
        this.canAddParameter = this.isParameterValid(currentParameter);

        return this.canAddValue;
    }

    /*checkValueFilled(parameter: ActionParameterModel, parameter_i: number, index: number) {
        const currentValue = parameter.parameterValues[index];
        this.canAddValue = this.isValueValid(currentValue.parameterValue);
        this.checkParameterFilled(parameter_i);
    }*/

    checkParameter(index: number) {
        const currentParameter = this.parameters[index];
        this.canAddParameter = this.isParameterValid(currentParameter);
    }

    get isFormValid(): boolean {
        const actionNameControl = this.actionForm.get('actionName');
        const actionDescriptionControl = this.actionForm.get('actionDescription');
        const actionUrlControl = this.actionForm.get('actionUrl');

        if (!actionNameControl || actionNameControl.invalid) {
            return false;
        }

        if (!actionDescriptionControl || actionDescriptionControl.invalid) {
            return false;
        }

        if (actionUrlControl && actionUrlControl.invalid) {
            return false;
        }

        return this.actionMethod.trim() !== ''
            && this.headers.length > 0
            && this.headers.every(header => this.isHeaderValid(header))
            && this.parameters.length > 0
            && this.parameters.every(parameter => this.isParameterValid(parameter));
    }

    isHeaderValid(header: ActionHeaderModel): boolean {
        return header.headerKey.trim() !== '' && header.headerValue.trim() !== '';
    }

    isParameterValid(parameter: ActionParameterModel): boolean {
        return parameter.name.trim() !== ''
            && parameter.description.trim() !== ''
            && parameter.parameterType.trim() !== '';
            //&& parameter.parameterValues.every(value => this.isValueValid(value.parameterValue));
    }

    isValueValid(value: string): boolean {
        return value.trim() !== '';
    }

    selectMethod(method: string) {
        this.actionMethod = method;
        this.isDropdownOpen = false;
    }

    toggleCheck() {
        this.isChecked = !this.isChecked;
    }

    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    closeDialog() {
        const filledFieldsCount = this.countFilledFields();

        if (filledFieldsCount >= 3) {
            this.alert.showSaveChanges(
                'Warning',
                'You have entered more than 3 fields, data will be lost if you close the dialog!',
                'Close'
            ).then((result: { isConfirmed: any; }) => {
                if (result.isConfirmed) {
                    this.resetFields();
                    this.dialog.closeNestedDialog(this.dialogRef);
                }
            });
            return;
        } else {
            this.resetFields();
            this.dialog.closeNestedDialog(this.dialogRef);
        }
    }

    private countFilledFields(): number {
        let count = 0;

        count += [this.actionName, this.actionDescription, this.actionUrl].filter(field => field.trim() !== '').length;
        count += this.isChecked ? 1 : 0;

        this.headers.forEach(header => {
            count += [header.headerKey, header.headerValue].filter(field => field.trim() !== '').length;
        });

        this.parameters.forEach(param => {
            count += [param.name, param.description, param.parameterType].filter(field => field.trim() !== '').length;
            count += param.parameterValues.filter(value => value.parameterValue.trim() !== '').length;
        });

        return count;
    }

    ngOnDestroy(): void {
        //this.resetListValues();
    }
}
