import { Injectable } from '@angular/core';

import { RouterService } from "./router.service";
import { AlertService } from "./alert.service";
import { DialogService } from "./dialog.service";

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    constructor(private alert: AlertService, private router: RouterService, private dialog: DialogService) { }

    setError(response: any) {
        if (response.status === 403) {
            localStorage.removeItem('token');
            this.alert.close();
            this.dialog.close();
            this.router.goToLogin();
        } else {
            this.alert.showError('Error', response.message);
        }
    }
}
