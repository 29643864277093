import { Component } from '@angular/core';

@Component({
    selector: 'app-filter',
    template: `
        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 43699">
                <path id="Vector" d="M1.46875 4.23852H2.54037C2.76813 5.79827 4.1145 6.99996 5.73647 6.99996C7.35841 6.99996 8.70478 5.79827 8.93253 4.23852H16.5312C16.7901 4.23852 17 4.02864 17 3.76977C17 3.51089 16.7901 3.30102 16.5312 3.30102H8.93253C8.70478 1.74124 7.35841 0.539551 5.73647 0.539551C4.1145 0.539551 2.76813 1.74124 2.54037 3.30102H1.46875C1.20987 3.30102 1 3.51089 1 3.76977C1 4.02864 1.20987 4.23852 1.46875 4.23852ZM5.73647 1.47705C7.00066 1.47705 8.02916 2.50555 8.02916 3.76977C8.02916 5.03396 7.00066 6.06246 5.73647 6.06246C4.47225 6.06246 3.44375 5.03396 3.44375 3.76977C3.44375 2.50555 4.47225 1.47705 5.73647 1.47705Z" fill="#FBFBFB" stroke="#FBFBFB" stroke-width="0.5"/>
                <path id="Vector_2" d="M16.5312 9.76144H15.4596C15.2319 8.20169 13.8855 7 12.2635 7C10.6416 7 9.29522 8.20169 9.06747 9.76144H1.46875C1.20987 9.76144 1 9.97131 1 10.2302C1 10.4891 1.20987 10.6989 1.46875 10.6989H9.06747C9.29522 12.2587 10.6416 13.4604 12.2635 13.4604C13.8855 13.4604 15.2319 12.2587 15.4596 10.6989H16.5312C16.7901 10.6989 17 10.4891 17 10.2302C17 9.97131 16.7901 9.76144 16.5312 9.76144ZM12.2635 12.5229C10.9993 12.5229 9.97084 11.4944 9.97084 10.2302C9.97084 8.966 10.9993 7.9375 12.2635 7.9375C13.5278 7.9375 14.5562 8.966 14.5562 10.2302C14.5562 11.4944 13.5278 12.5229 12.2635 12.5229Z" fill="#FBFBFB" stroke="#FBFBFB" stroke-width="0.5"/>
            </g>
        </svg>
    `,
})
export class FilterComponent {

}
