import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import {
    KnowledgeAttachmentModel,
    KnowledgeModel,
    KnowledgeTripletModel
} from "../../../core/models/knowledge/knowledge.model";
import { DialogService } from "../../../core/services/dialog.service";
import { KnowledgeService } from "../../../core/services/knowledge.service";
import { AlertService } from "../../../core/services/alert.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ErrorService } from "../../../core/services/error.service";

@Component({
    selector: 'app-edit-knowledge-modal',
    templateUrl: './edit-knowledge-modal.component.html',
    styleUrl: './edit-knowledge-modal.component.scss'
})
export class EditKnowledgeModalComponent implements OnInit, OnDestroy {
    dialogTitle = 'Edit Knowledge';
    nameLabel = 'Name';
    knowledgeNameLabel = 'Enter knowledge name';
    textLabel = 'Text Input';
    textareaLabel = 'Enter knowledge text here...';
    buttonLabel = 'Save changes';

    knowledgeId: string = '';
    knowledgeName: string = '';
    knowledgeType: string = '';
    knowledgeText: string = '';
    triplets: KnowledgeTripletModel[] = [];
    attachments: KnowledgeAttachmentModel[] = [];
    attachment: File | null = null;
    dropzoneAttachment: any;
    knowledgeForm!: FormGroup; // Using reactive forms
    errorNameRequiredMessage = 'Knowledge name is required.'
    errorTextRequiredMessage = 'Knowledge text is required.'
    errorMinLengthMessage: string = 'Knowledge name must contain more than 3 characters.'

    knowledgeAttachments: any[] = [];

    @HostListener('document:keydown.escape', ['$event'])
    handleEscape(event: KeyboardEvent) {
        this.closeDialog();
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: KnowledgeModel | null,
        private dialogRef: MatDialogRef<EditKnowledgeModalComponent>,
        private dialog: DialogService,
        private knowledge: KnowledgeService,
        private alert: AlertService,
        private error: ErrorService,
        private formBuilder: FormBuilder) { }

    async ngOnInit() {
        this.initializeForm();

        if (this.data) {
            this.alert.showLoading();

            this.knowledgeId = this.data.id;
            this.knowledgeName = this.data.name;
            this.knowledgeType = this.data.knowledgeType;

            // TODO: Delete after the back is straightened
            if ((this.data.knowledgeType === 'Text' || this.data.knowledgeType === 'File')) {
                const lastDocument = this.data.documents ? this.data.documents[0] : null;

                if (lastDocument && lastDocument.version.attachments && lastDocument.version.attachments.length > 0) {
                    this.attachments = [];
                    const attachments = lastDocument.version.attachments;

                    for (const attachment of attachments) {
                        const attachmentId = attachment.id + '.' + attachment.attachmentType;
                        const attachmentName = attachment.name + '.' + attachment.attachmentType;
                        const response = await this.knowledge.getKnowledgeAttachment(this.data.id, attachmentId);

                        if (response.status !== 200) {
                            if (response.status === 403) {
                                this.closeDialog();
                            }
                            this.error.setError(response);
                            return;
                        }

                        const originalFile = response.body as File;
                        const newFile = new File([originalFile], attachmentName, { type: originalFile.type });
                        this.knowledgeAttachments.push(newFile);
                    }

                    this.knowledge.setSelectedKnowledgeAttachments(this.knowledgeAttachments);
                }

                const response = await this.knowledge.getKnowledgeDocument(this.data.id);

                if (response.status !== 200) {
                    if (response.status === 403) {
                        this.closeDialog();
                    }
                    this.error.setError(response);
                    return;
                }

                this.knowledgeText = response.body;

                this.knowledgeForm.patchValue({
                    knowledgeName: this.data.name,
                    knowledgeText: this.knowledgeText
                });

            } else if (this.data.knowledgeType === 'Triplet' && this.data.triplets) {
                this.triplets = this.data.triplets;
            }

            this.alert.close();
        }
    }

    private initializeForm() {
        this.knowledgeForm = this.formBuilder.group({
            knowledgeName: ['', Validators.required],
            knowledgeText: ['', Validators.required]
        });
    }

    getKnowledgeFormElement(control: string) {
        return this.knowledgeForm.get(control);
    }

    isFieldInvalid(controlName: string): boolean {
        const control = this.knowledgeForm.get(controlName);
        return !!(control?.invalid && (control.dirty || this.knowledgeForm.get(controlName)?.touched)); // Parse into boolean type with !!
    }

    resetFields(){
        this.knowledgeForm.reset();
    }

    get isFormInvalid(): boolean {
        return this.knowledgeForm.invalid;
    }

    async updateKnowledge() {
        this.alert.showLoading();

        const knowledge: KnowledgeModel = {} as KnowledgeModel;
        //knowledge.id = this.knowledgeId;
        knowledge.name = this.knowledgeForm.get('knowledgeName')?.value;
        knowledge.knowledgeType = this.knowledgeType;

        let knowledgeResponse;

        if (this.knowledgeType === 'Text' || this.knowledgeType === 'File') {
            const formData = new FormData();
            formData.append('config', JSON.stringify(knowledge));
            formData.append('document', new File([new Blob([this.knowledgeForm.get('knowledgeText')?.value])], 'text.txt'));

            if (this.dropzoneAttachment !== undefined && this.dropzoneAttachment.files) {
                this.dropzoneAttachment.files.forEach((file: any) => {
                    formData.append('attachments', file);
                });
            } else {
                //formData.append('attachments', new Blob());
            }

            knowledgeResponse = await this.knowledge.updateDocumentKnowledge(this.knowledgeId, formData);
        } else if (this.knowledgeType === 'Triplet') {
            knowledge.triplets = this.triplets;
            knowledgeResponse = await this.knowledge.updateTripletKnowledge(this.knowledgeId, knowledge);
        } else {
            this.alert.showError('Error', 'Invalid knowledge type');
            return;
        }

        if (knowledgeResponse.status !== 200) {
            if (knowledgeResponse.status === 403) {
                this.closeDialog();
            }
            this.error.setError(knowledgeResponse);
            return;
        }

        // refresh list of knowledge
        const response = await this.knowledge.getKnowledgePaginationList(1, 10, '');

        if (response.status !== 200) {
            if (response.status === 403) {
                this.closeDialog();
            }
            this.error.setError(response);
        } else {
            this.knowledge.setKnowledgePaginationResponse(response.body);
        }

        this.closeDialog();
        this.alert.close();
        this.alert.showSucess('Successfully updated!', 'Check knowledge availability in Learning Hub.');
    }

    handleAttachmentDropzone(dropzone: any) {
        this.dropzoneAttachment = dropzone;
    }

    handleTriplets(triplets: KnowledgeTripletModel[]) {
        console.log('tripleti:', triplets);
        this.triplets = triplets;
    }

    get isFormValid(): boolean {
        const knowledgeNameControl = this.knowledgeForm.get('knowledgeName');
        const knowledgeTextControl = this.knowledgeForm.get('knowledgeText');

        if (!knowledgeNameControl || knowledgeNameControl.invalid) {
            return false;
        }

        if (this.knowledgeType === 'Text' || this.knowledgeType === 'File') {
            if (!knowledgeTextControl || knowledgeTextControl.invalid) {
                return false;
            }
        }

        if (this.knowledgeType === 'Triplet' && this.triplets.length === 0) {
            return false;
        }

        return true;
    }

    getClass() {
        return {
            'triplets-active': this.triplets.length > 0,
        };
    }

    closeDialog() {
        this.resetFields();
        this.dialog.closeNestedDialog(this.dialogRef);
    }

    ngOnDestroy(): void {
    }
}
