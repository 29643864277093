import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { EventService } from "../../../core/services/event.service";
import { AssistantService } from "../../../core/services/assistant.service";
import { DialogService } from "../../../core/services/dialog.service";
import { KnowledgeService } from "../../../core/services/knowledge.service";
import { ActionService } from "../../../core/services/action.service";
import { AlertService } from "../../../core/services/alert.service";
import { ActionModel } from "../../../core/models/action/action.model";
import { ErrorService } from "../../../core/services/error.service";

@Component({
    selector: 'app-search-area',
    templateUrl: './search-area.component.html',
    styleUrl: './search-area.component.scss'
})
export class SearchAreaComponent implements OnInit, OnDestroy {
    @Input() tab: string = '';
    @Input() disabled: boolean = false;

    loupeImage: string = 'assets/icons/loupe.svg';

    searchPlaceholder: string = '';
    createButton: string = '';
    searchValue: string = '';
    filterValue: { type: string[] | null; selected: boolean } = { type: [], selected: false };
    isFilterOptionsOpen: boolean = false;
    selectedItemSelected = new Set<string>();
    selectedKnowledgeChannel = new Set<string>();
    actions: ActionModel[] = [ ];

    navBarOptionSubscription!: Subscription;
    currentTabSubscription!: Subscription;
    knowledgeDisplaySubscription!: Subscription;
    actionSubscription!: Subscription;

    constructor(
        private dialog: DialogService,
        private event: EventService,
        private assistant: AssistantService,
        private knowledge: KnowledgeService,
        private action: ActionService,
        private alert: AlertService,
        private error: ErrorService) {}

    ngOnInit(): void {
        this.navBarOptionSubscription = this.event.getContentFromNavbarOption().subscribe(navBarOption => {
            if (navBarOption) {
                this.searchValue = '';
                this.event.setSearchValue(this.searchValue);

                this.filterValue = { type: null, selected: false };
                this.event.setFilterValue(this.filterValue);

                this.searchPlaceholder = navBarOption.search;
                this.createButton = navBarOption.create;
            }
        });
    }

    create() {
        if (this.tab === 'Assistants') { this.dialog.openCreateAssistantDialog(); }
        if (this.tab === 'Learning Hub' || this.tab === 'AssistantLearningHub') { this.dialog.openCreateKnowledgeDialog(); }
        if (this.tab === 'Actions' || this.tab === 'AssistantActions') { this.dialog.openActionDialog(); }
    }

    onOpenFilterOptions() {
        this.isFilterOptionsOpen = !this.isFilterOptionsOpen;
    }

    onCloseFilterOptions() {
        this.isFilterOptionsOpen = false;
    }

    async toggleItemSelected(option: string) {
        if (this.selectedItemSelected.has(option)) {
            this.selectedItemSelected.delete(option);
        } else {
            this.selectedItemSelected.add(option);
        }

        await this.filter();
    }

    async toggleKnowledgeChannel(option: string) {
        if (this.selectedKnowledgeChannel.has(option)) {
            this.selectedKnowledgeChannel.delete(option);
        } else {
            this.selectedKnowledgeChannel.add(option);
        }

        await this.filter();
    }

    async search() {
        if (this.searchValue === null || this.searchValue === undefined) { return; }
        this.event.setSearchValue(this.searchValue);
        await this.show(this.searchValue);
    }

    async removeSearch() {
        this.searchValue = '';
        this.event.setSearchValue(this.searchValue);
        await this.show(this.searchValue);
    }

    async show(searchValue: string) {
        if (this.tab === 'Assistants') {
            const response = await this.assistant.fetchAssistants(searchValue);
            if (response.status !== 200) {
                this.error.setError(response);
            } else {
                this.assistant.setAssistantsResponse(response.body);
            }
        }
        if (this.tab === 'Learning Hub') {
            const response = await this.knowledge.getKnowledgePaginationList(1, 10, searchValue); // TODO: Page, perPage should be considered to be inside config service
            this.alert.close();
            if (response.status !== 200) {
                this.error.setError(response);
            } else {
                this.knowledge.setKnowledgePaginationResponse(response.body);
            }
        }
        if (this.tab === 'AssistantLearningHub') {
            console.log('Assistant knowledge list')
        }
        if (this.tab === 'Actions') {
            const response = await this.action.getActionPaginationList(1, 10, searchValue); // TODO: Page, perPage should be considered to be inside config service

            this.alert.close();
            if (response.status !== 200) {
                this.error.setError(response);
            } else {
                this.action.setActionPaginationResponse(response.body);
            }
        }
        if (this.tab === 'AssistantActions') {
            console.log('Assistant action list')
        }
    }

    async filter() {
        if (this.selectedKnowledgeChannel.size === 0 && this.selectedItemSelected.size === 0) {
            this.filterValue.type = null;
            this.filterValue.selected = false;
        } else {
            this.filterValue.selected = this.selectedItemSelected.size > 0;

            if (this.selectedKnowledgeChannel.size > 0){
                this.filterValue.type = Array.from(this.selectedKnowledgeChannel);
            } else {
                this.filterValue.type = null;
            }
        }

        this.event.setFilterValue(this.filterValue);
    }

    ngOnDestroy() {
        if (this.navBarOptionSubscription) { this.navBarOptionSubscription.unsubscribe(); }
        if (this.currentTabSubscription) { this.currentTabSubscription.unsubscribe(); }
        if (this.knowledgeDisplaySubscription) { this.knowledgeDisplaySubscription.unsubscribe(); }
        if (this.actionSubscription) { this.actionSubscription.unsubscribe(); }
    }
}
