import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { MatDialogModule } from "@angular/material/dialog";

import { ExampleDirective } from "./directives/example.directive";
import { ExamplePipe } from "./pipes/example.pipe";
import { HeaderComponent } from "./components/header/header.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { NavBarComponent } from "./components/nav-bar/nav-bar.component";
import { KnowledgeComponent } from "./svgs/knowledge.component";
import { ActionComponent } from "./svgs/action.component";
import { DataInsightComponent } from "./svgs/data-insight.component";
import { AssistantIconComponent } from "./svgs/assistant-icon.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { SearchAreaComponent } from "./components/search-area/search-area.component";
import { MatFormField, MatFormFieldModule } from "@angular/material/form-field";
import { MatInput, MatInputModule } from "@angular/material/input";
import { CloseComponent } from "./svgs/close.component";
import { SimilarityThresholdRangeComponent } from "./components/similarity-threshold-range/similarity-threshold-range.component";
import { AnswerThresholdRangeComponent } from "./components/answer-threshold-range/answer-threshold-range.component";
import { CreatedAssistantModalComponent } from "./components/created-assistant-modal/created-assistant-modal.component";
import { GoToAssistantComponent } from "./svgs/go-to-assistant.component";
import { CorrectComponent } from "./svgs/correct.component";
import { CopyComponent } from "./svgs/copy.component";
import { CreateKnowledgeModalComponent } from "./components/create-knowledge-modal/create-knowledge-modal.component";
import { EditComponent } from "./svgs/edit.component";
import {
    AddKnowledgeToAssistantModalComponent
} from "./components/add-knowledge-to-assistant-modal/add-knowledge-to-assistant-modal.component";
import { ArrowComponent } from "./svgs/arrow.component";
import { AccountComponent } from "./components/account/account.component";
import { PlusComponent } from "./svgs/plus.component";
import { DeleteComponent } from "./svgs/delete.component";
import { EditKnowledgeModalComponent } from "./components/edit-knowledge-modal/edit-knowledge-modal.component";
import { AttachmentUploadComponent } from "./components/attachment-upload/attachment-upload.component";
import { TripletKnowledgeComponent } from "./components/triplet-knowledge/triplet-knowledge.component";
import { EntityModalComponent } from "./components/entity-modal/entity-modal.component";
import { TripletModalComponent } from "./components/triplet-modal/triplet-modal.component";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { ActionModalComponent } from "./components/action-modal/action-modal.component";
import { CreateAssistantModalComponent } from "./components/create-assistant-modal/create-assistant-modal.component";
import { EditAssistantModalComponent } from "./components/edit-assistant-modal/edit-assistant-modal.component";
import { RelationModalComponent } from "./components/relation-modal/relation-modal.component";
import {
    AddActionToAssistantModalComponent
} from "./components/add-action-to-assistant-modal/add-action-to-assistant-modal.component";
import { OutsideClickDirective } from "./directives/outside-click.directive";
import { DesignsModalComponent } from "./components/designs-modal/designs-modal.component";
import { MenuComponent } from "./svgs/menu.component";
import { LinkComponent } from "./svgs/link.component";
import { SoonModalComponent } from "./components/soon-modal/soon-modal.component";
import { SelectAssistantModalComponent } from "./components/select-assistant-modal/select-assistant-modal.component";
import {
    AssistantConfigurationComponent
} from "./components/assistant-configuration/assistant-configuration.component";
import { FadeInOutDirective } from "./directives/fade-in-out";
import { ContactUsModalComponent } from "./components/contact-us-modal/contact-us-modal.component";
import {
    AttachmentPreviewModalComponent
} from "./components/attachment-preview-modal/attachment-preview-modal.component";
import { SafePipe } from "./pipes/safe.pipe";
import { CommentModalComponent } from "./components/comment-modal/comment-modal.component";
import { SelectArrowComponent } from "./svgs/select-arrow.component";
import { FilterComponent } from "./svgs/filter.component";

@NgModule({
    // declaration of components, directives, guards, pipes, svgs from SHARED directory
    declarations: [
        ExampleDirective,
        ExamplePipe,

        HeaderComponent,
        AssistantIconComponent,
        DataInsightComponent,
        LoaderComponent,
        NavBarComponent,
        KnowledgeComponent,
        ActionComponent,
        SearchAreaComponent,
        CloseComponent,
        SimilarityThresholdRangeComponent,
        AnswerThresholdRangeComponent,
        GoToAssistantComponent,
        CorrectComponent,
        CopyComponent,
        EditComponent,
        ArrowComponent,
        AccountComponent,
        PlusComponent,
        DeleteComponent,
        AttachmentUploadComponent,
        TripletKnowledgeComponent,
        FileUploadComponent,
        OutsideClickDirective,
        MenuComponent,
        LinkComponent,
        SoonModalComponent,
        AssistantConfigurationComponent,
        FadeInOutDirective,
        ContactUsModalComponent,
        AttachmentPreviewModalComponent,
        SafePipe,
        CommentModalComponent,
        SelectArrowComponent,
        FilterComponent,

        // modals
        CreateAssistantModalComponent,
        CreatedAssistantModalComponent,
        CreateKnowledgeModalComponent,
        AddKnowledgeToAssistantModalComponent,
        AddActionToAssistantModalComponent,
        EditAssistantModalComponent,
        EditKnowledgeModalComponent,
        EntityModalComponent,
        TripletModalComponent,
        RelationModalComponent,
        ActionModalComponent,
        DesignsModalComponent,
        SelectAssistantModalComponent
    ],
    exports: [
        HeaderComponent,
        AssistantIconComponent,
        DataInsightComponent,
        LoaderComponent,
        NavBarComponent,
        KnowledgeComponent,
        ActionComponent,
        SearchAreaComponent,
        SimilarityThresholdRangeComponent,
        AnswerThresholdRangeComponent,
        GoToAssistantComponent,
        CorrectComponent,
        CopyComponent,
        EditComponent,
        ArrowComponent,
        AccountComponent,
        PlusComponent,
        DeleteComponent,
        AttachmentUploadComponent,
        TripletKnowledgeComponent,
        FileUploadComponent,
        OutsideClickDirective,
        MenuComponent,
        LinkComponent,
        SoonModalComponent,
        AssistantConfigurationComponent,
        FadeInOutDirective,
        ContactUsModalComponent,
        FilterComponent,

        // modals
        CreateAssistantModalComponent,
        CreatedAssistantModalComponent,
        CreateKnowledgeModalComponent,
        AddKnowledgeToAssistantModalComponent,
        AddActionToAssistantModalComponent,
        EditAssistantModalComponent,
        EditKnowledgeModalComponent,
        EntityModalComponent,
        TripletModalComponent,
        RelationModalComponent,
        ActionModalComponent,
        DesignsModalComponent,
        SelectAssistantModalComponent,
        SelectArrowComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatDialogModule,
        NgOptimizedImage,
        MatTooltipModule,
        MatButtonModule,
        RouterLink,
        RouterLinkActive,
        MatToolbarModule,
        ReactiveFormsModule,
        MatFormField,
        MatInput,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        ReactiveFormsModule
    ]
})
export class SharedModule {}
