<div class="box-search">
    <div class="section-actions">
        <div class="search-container">
            <img [src]="loupeImage" alt="Loupe icon">
            <input type="text" name="search-placeholder" id="id-search-project"
                   [placeholder]="searchPlaceholder" [(ngModel)]="searchValue"
                   (keyup.enter)="search()" (input)="search()" [disabled]="disabled" />
            <button *ngIf="searchValue" id="id-remove-search" type="button" class="remove-search-button" (click)="removeSearch()">
                <app-close width="12"></app-close>
            </button>
        </div>
        <div *ngIf="this.tab === 'AssistantLearningHub' || this.tab === 'AssistantActions'" class="filter-container"
             appOutsideClick (outsideClick)="onCloseFilterOptions()">
            <button id="id-filter-option" type="button" class="filter-option-button" (click)="onOpenFilterOptions()">
                <app-filter></app-filter>
            </button>
            <div *ngIf="isFilterOptionsOpen" class="filter-options-container">
                <div *ngIf="this.tab === 'AssistantLearningHub'" class="knowledge-channel-container">
                    <p>Knowledge Channel</p>
                    <div class="border"></div>
                    <div class="filter-option" (click)="toggleKnowledgeChannel('File')">
                        <input type="checkbox" value="File" id="item-file"
                               [checked]="selectedKnowledgeChannel.has('File')"> File
                    </div>
                    <div class="filter-option" (click)="toggleKnowledgeChannel('Text')">
                        <input type="checkbox" value="File" id="item-text"
                               [checked]="selectedKnowledgeChannel.has('Text')"> Text
                    </div>
                    <div class="filter-option" (click)="toggleKnowledgeChannel('Triplets')">
                        <input type="checkbox" value="File" id="item-triplets"
                               [checked]="selectedKnowledgeChannel.has('Triplets')"> Triplets
                    </div>
                </div>
                <div class="item-selected-container">
                    <p>Item Selected</p>
                    <div class="border"></div>
                    <div class="filter-option" (click)="toggleItemSelected('Selected')">
                        <input type="checkbox" value="Selected" id="item-selected"
                               [checked]="selectedItemSelected.has('Selected')">Selected
                    </div>
                </div>
            </div>
        </div>

        <button id="id-create-option" type="button" class="create-option-button" (click)="create()">
            <app-plus></app-plus>
            <span>{{ createButton }}</span>
        </button>
    </div>
</div>
