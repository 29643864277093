import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Clipboard } from "@angular/cdk/clipboard";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";

import { DialogService } from "../../../core/services/dialog.service";
import { ConfigService } from "../../../core/services/config.service";
import { DesignModel } from "../../../core/models/misc/design.model";

@Component({
    selector: 'app-created-assistant-modal',
    templateUrl: './created-assistant-modal.component.html',
    styleUrl: './created-assistant-modal.component.scss'
})
export class CreatedAssistantModalComponent implements OnInit, OnDestroy {
    sucessImage: string = 'assets/icons/sucess.svg';
    dialogTitle = 'Assistant sucessfully created!';
    dialogDescription = 'Add knowledge or go to Assistant screen.';
    knowledgeButton = 'Add Knowledge to Assistant';
    assistantButton = 'Go to Assistant';
    goToLinkButton = 'Go to link';

    designs: DesignModel[] = [];

    isLinkCopied = [false, false, false, false];
    isGoToAssistant: boolean = false;

    designsSubscription!: Subscription;

    constructor(
        private dialog: DialogService,
        private clipboard: Clipboard,
        private config: ConfigService,
        @Inject(MAT_DIALOG_DATA) public data: any | null,
        private dialogRef: MatDialogRef<CreatedAssistantModalComponent>
    ) { }

    ngOnInit(): void {
         this.designsSubscription = this.config.getAssistantDesigns(this.data.id).subscribe(designs => {
             this.designs = designs
         });
    }

    copyTextMessage(content: string, index: number) {
        this.clipboard.copy(content);
        this.isLinkCopied[index] = true;

        setTimeout(() => { this.isLinkCopied[index] = false; }, 2000);
    }

    goToLink(link: string) {
        window.open(link, '_blank');
    }

    addKnowledge() {
        this.closeDialog();

        if (this.data) {
            this.dialog.openAddKnowledgeToAssistantDialog(this.data)
        }
    }

    goToAssistant() {
        this.isGoToAssistant = true;
        /*const design4 = this.designs[0];
        window.open(design4.url, '_blank');*/
    }

    closeDialog() {
        this.dialog.closeNestedDialog(this.dialogRef);
    }

    ngOnDestroy(): void {
        if (this.designsSubscription) { this.designsSubscription.unsubscribe(); }
    }
}
