import { Injectable } from '@angular/core';

import { NavBarOptionModel } from "../models/navBarOption.model";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class EventService {
    currentNavbarTab = new BehaviorSubject<string>('');
    contentFromNavbarOption = new BehaviorSubject<NavBarOptionModel | null>(null);
    answerLengthValue = new BehaviorSubject<number>(20); // Default value
    similarityThrasholdValue = new BehaviorSubject<number>(0); // Default value
    searchValue = new BehaviorSubject<string>('');
    filterValue = new BehaviorSubject<any>('');

    constructor() { }

    // getters
    getAnswerLength(): Observable<number> {
        return this.answerLengthValue;
    }

    getSimilarityThrashold(): Observable<number> {
        return this.similarityThrasholdValue;
    }

    getContentFromNavbarOption(): Observable<NavBarOptionModel | null> {
        return this.contentFromNavbarOption;
    }

    getCurrentTab(): Observable<string> {
        return this.currentNavbarTab;
    }

    getSearchValue(): Observable<string> {
        return this.searchValue;
    }

    getFilterValue(): Observable<any> {
        return this.filterValue;
    }

    // setters
    setAnswerLength(length: number) {
        this.answerLengthValue.next(length);
    }

    setSimilarityThrashold(similarity: number) {
        this.similarityThrasholdValue.next(similarity);
    }

    setContentFromNavbarOption(navBaroption: NavBarOptionModel | null) {
        this.contentFromNavbarOption.next(navBaroption);
    }

    setCurrentTab(tab: string) {
        this.currentNavbarTab.next(tab);
    }

    setSearchValue(search: string) {
        this.searchValue.next(search);
    }

    setFilterValue(filter: any) {
        this.filterValue.next(filter);
    }
}
