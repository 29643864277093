import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";

import { ActionId, ActionModel, AssistantActionDisplayModel } from "../../../core/models/action/action.model";
import { AlertService } from "../../../core/services/alert.service";
import { DialogService } from "../../../core/services/dialog.service";
import { ActionService } from "../../../core/services/action.service";
import { AssistantService } from "../../../core/services/assistant.service";
import { NavBarOptionModel } from "../../../core/models/navBarOption.model";
import { EventService } from "../../../core/services/event.service";
import { ErrorService } from "../../../core/services/error.service";

@Component({
    selector: 'app-add-action-to-assistant-modal',
    templateUrl: './add-action-to-assistant-modal.component.html',
    styleUrl: './add-action-to-assistant-modal.component.scss'
})
export class AddActionToAssistantModalComponent implements OnInit, OnDestroy {
    dialogTitle = 'Manage action to ';
    saveButton = 'Save changes';
    noSearchResultSection: string[] = [
        `No search results found!`,
        `Try adjusting your search criteria or create new action if it doesn't exist.`
    ]

    assistantData: any
    allActions: ActionModel[] = [ ];
    assistantActionDisplayList: AssistantActionDisplayModel[] = [ ];
    originalAssistantActionDisplay: AssistantActionDisplayModel[] = [ ];
    searchValue: string = '';
    filterValue: { type: string[] | null; selected: boolean } = { type: [], selected: false };
    checkedActionMap: { [key: string]: boolean } = {};

    assistantActionsSubscription!: Subscription;
    searchValueSubscription!: Subscription;
    filterValueSubscription!: Subscription;

    @HostListener('document:keydown.escape', ['$event'])
    handleEscape(event: KeyboardEvent) {
        this.closeDialog();
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any | null,
        private alert: AlertService,
        private dialog: DialogService,
        private action: ActionService,
        private assistant: AssistantService,
        private event: EventService,
        private error: ErrorService,
        private dialogRef: MatDialogRef<AddActionToAssistantModalComponent>) {
    }

    async ngOnInit() {
        this.assistantData = this.data;
        this.dialogTitle = this.dialogTitle + this.assistantData.name;

        const option: NavBarOptionModel = { search: 'Type here to search action...', create: 'Create new action' };
        this.event.setContentFromNavbarOption(option);

        await this.loadActions();

        this.assistantActionsSubscription = this.action.getAssistantActionsResponse().subscribe(actions => {
            if (actions != null) {
                this.allActions = actions;
                this.checkAssistantActions();
            }
        })

        this.searchValueSubscription = this.event.getSearchValue().subscribe(search => {
            this.searchValue = search || '';
            this.applyFilters();
        });

        this.filterValueSubscription = this.event.getFilterValue().subscribe(filter => {
            if (filter) {
                this.filterValue.type = filter.type;
                this.filterValue.selected = filter.selected;
            } else {
                this.filterValue = { type: [], selected: false };
            }
            this.applyFilters();
        });
    }

    async loadActions() {
        this.alert.showLoading();
        const response = await this.action.getActionList('');
        this.allActions = response.body;

        // Create a map of action id and its assigned status
        this.allActions.forEach(action => {
            if (this.assistantData.actions) {
                this.checkedActionMap[action.id] = this.assistantData.actions.some((a: ActionModel) => a.id === action.id);
            } else {
                this.checkedActionMap[action.id] = false;
            }
        });

        this.checkAssistantActions();
        this.alert.close();
    }

    checkAssistantActions() {
        this.assistantActionDisplayList = this.allActions.map(action => ({
            action: action,
            assignedToAssistant: this.checkedActionMap[action.id] || false
        }));

        this.originalAssistantActionDisplay = this.assistantActionDisplayList;
    }

    toggleCheckbox(assistantActionDisplay: AssistantActionDisplayModel): void {
        assistantActionDisplay.assignedToAssistant = !assistantActionDisplay.assignedToAssistant;
        this.checkedActionMap[assistantActionDisplay.action.id] = assistantActionDisplay.assignedToAssistant;
    }

    addNewAction() {
        this.dialog.openActionDialog()
    }

    isAssignableActionListEmpty(): boolean {
        return !(this.assistantActionDisplayList && this.assistantActionDisplayList.length > 0);
    }

    applyFilters() {
        this.assistantActionDisplayList = this.allActions
        .filter(action =>
            (!this.searchValue || action.name.toLowerCase().includes(this.searchValue.toLowerCase()))
        )
        /*.filter(action =>
            (!this.filterValue.type || this.filterValue.type.length === 0 || this.filterValue.type.includes(action.knowledgeType))
        )*/
        .map(action => ({
            action: action,
            assignedToAssistant: this.checkedActionMap[action.id] || false
        }));

        if (this.filterValue.selected) {
            this.assistantActionDisplayList = this.assistantActionDisplayList.filter(
                assistantActionDisplay => assistantActionDisplay.assignedToAssistant === this.filterValue.selected
            );
        }
    }

    async save() {
        this.alert.showLoading();

        try {
            const actionIds: ActionId[] = Object.keys(this.checkedActionMap)
            .filter(key => this.checkedActionMap[key])
            .map(id => ({ id }));

            const response = await this.action.assignAssistantActions(this.assistantData.id, actionIds);
            if (response.status !== 200) {
                if (response.status === 403) {
                    this.closeDialog();
                }
                this.error.setError(response);
                return;
            }

            const getAgent = await this.assistant.fetchAssistant(this.assistantData.id);
            if (getAgent.status !== 200) {
                if (getAgent.status === 403) {
                    this.closeDialog();
                }
                this.error.setError(getAgent);
                return;
            }

            this.assistant.setSelectedAssistantResponse(getAgent.body);

            this.closeDialog();
            this.alert.close();
            this.alert.showSucess('Actions assigned successfully.', 'Check your action list in Assistant Actions tab.');
        } catch (error) {
            console.error('Error assigning knowledgeList:', error);
        }
    }

    closeDialog() {
        this.dialog.closeNestedDialog(this.dialogRef);
    }

    ngOnDestroy() {
        this.assistantActionsSubscription.unsubscribe();
        this.searchValueSubscription.unsubscribe();
    }
}
